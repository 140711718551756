module.exports = function anonymous(locals, escapeFn, include, rethrow
) {
escapeFn = escapeFn || function (markup) {
  return markup == undefined
    ? ''
    : String(markup)
      .replace(_MATCH_HTML, encode_char);
};
var _ENCODE_HTML_RULES = {
      "&": "&amp;"
    , "<": "&lt;"
    , ">": "&gt;"
    , '"': "&#34;"
    , "'": "&#39;"
    }
  , _MATCH_HTML = /[&<>'"]/g;
function encode_char(c) {
  return _ENCODE_HTML_RULES[c] || c;
};
;
  var __output = "";
  function __append(s) { if (s !== undefined && s !== null) __output += s }
  with (locals || {}) {
    ; __append("<script id=\"hotjar-page-script\">let hotjar_id=parseInt(\"")
    ; __append(escapeFn( state.analytics.hotjar_id ))
    ; __append("\"),hotjar_version=parseInt(\"")
    ; __append(escapeFn( state.analytics.hotjar_version ))
    ; __append("\");((e,t,h,i)=>{e.hj=e.hj||function(){(e.hj.q=e.hj.q||[]).push(arguments)},e._hjSettings={hjid:hotjar_id,hjsv:hotjar_version},h=t.getElementsByTagName(\"head\")[0],(i=t.createElement(\"script\")).async=1,i.src=\"https://static.hotjar.com/c/hotjar-\"+e._hjSettings.hjid+\".js?sv=\"+e._hjSettings.hjsv,h.appendChild(i)})(window,document)</script>")
  }
  return __output;

}