/*
 * File: general.utilities.js
 * Project: our-wave-exhibits-app
 *
 * Created by Brendan Michaelsen on December 31, 2021 at 2:14 PM
 * Copyright © 2021 - 2024 Our Wave, Inc. All rights reserved.
 *
 * Last Modified: March 15, 2025 at 10:24 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Requires
 */

// Constants
const { AVAILABLE_LOCALES, REDIRECT_LOCALES } = require('../constants/general.constants');


/**
 * Utilities
 */

const randomizeEntryPosition = () => {

	// Create angle offset
	let angle = (Math.random() * (5 - 12) + 12).toFixed(1); // Between 5 and 12 degrees
	const sign = (Math.random() >= 0.5) ? '-' : '';
	angle = sign + angle;

	// Create padding
	const padding = (Math.random() * (20 - 28) + 28).toFixed(0); // Between 20px and 28px padding

	// Return values
	return { angle, padding };
};

const reduceToBaseURL = (p) => {
	let path = p;
	[...AVAILABLE_LOCALES,
		...REDIRECT_LOCALES].forEach((locale) => {
		const testArray = path.split(locale);
		if (testArray.length > 1 && testArray[0] === '/') {
			const testPath = testArray[1];
			if ((testPath.length === 0 || testPath.charAt(0) === '/' || testPath.charAt(0) === '?')) {
				path = path.replace(locale, '').replace('//', '/');
			}
		}
	});
	return path;
};


/**
 * Exports
 */

module.exports = {
	randomizeEntryPosition,
	reduceToBaseURL
};
